<template>
  <LoadingPage v-if="loaded" />
  <div v-else>
    <TrackingTemplate
      :order="order"
      :status-step="statusStep"
      :add-info-color="addInfoColor"
    />
  </div>
</template>

<script>
//general components
import TrackingService from "@/services/TrackingService";
import NProgress from "nprogress";
import TrackingTemplate from "./TrackingTemplate.vue";
import LoadingPage from "@/components/loadings/LoadingPage.vue";

const trackingService = new TrackingService();

export default {
  name: "TrackingsView",
  components: {
    TrackingTemplate,
    LoadingPage,
  },
  data: () => {
    return {
      loaded: false,
      prevRoute: null,
      order: {},
      statusStep: 0,
      addInfoColor: "",
      /* old trackingcode mapping
      imageZero: [100, 200, 300, 401, 402, 450, 451, 452, 453, 454],
      imageOne: [400, 403, 500, 617, 618],
      imageTwo: [
        600, 601, 602, 603, 604, 605, 606, 607, 608, 609, 610, 611, 612, 613,
        614, 615, 616, 800, 803, 804, 806, 807, 810, 801, 802, 805, 808, 809,
        1000, 1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1100, 1101, 1200,
      ],
      imageThree: [700, 701, 702, 703],
      imageFour: [900, 901, 902, 903],
      imageError: [
        801, 802, 805, 808, 809, 1000, 1001, 1002, 1003, 1004, 1005, 1006, 1007,
        1008, 1100, 1101, 1200, 1303, 1350, 1351, 1352, 1400, 1450, 1451,
      ],
      imageSix: [1300, 1301, 1302, 1304, 1306],
      //imageSixError: [1303, 1350, 1351, 1352, 1400, 1450, 1451],
      imageSeven: [1305, 1400],
      */
      /* primaryColor: "#006464",
        secondaryColor: '#F2F2F2', */
      stepColors: {
        0: "#000F5A",
        1: "#003c50",
        2: "#007850",
        3: "#00aa50",
        4: "#00dc50",
        danger: "#DC0000",
        warning: "#c8c800",
      },
      windowWidth: window.innerWidth,
    };
  },
  created() {
    this.getTracking();
  },
  methods: {
    getTracking() {
      trackingService
        .getTrackingOrder(
          this.$route.params.reference,
          this.$route.params.recaptcha_token
        )
        .then((response) => {
          if (response.data?.status === "ERROR") {
            this.$router.push({ name: "track_my_order" });
            return;
          }
          this.order = response.data.data;
          const tracking_code =
            this.order.volumes[0].current_status.tracking_code.toString();

          if (["100", "200", "300"].includes(tracking_code)) {
            this.statusStep = 0;
          } else if (
            (tracking_code.startsWith("40") && tracking_code.length == 3) ||
            tracking_code === "500"
          ) {
            this.statusStep = 1;
          } else if (
            tracking_code.startsWith("7") &&
            tracking_code.length == 3
          ) {
            this.statusStep = 3;
          } else if (
            (tracking_code.startsWith("9") && tracking_code.length == 3) ||
            (tracking_code.startsWith("13") && tracking_code.length == 4) ||
            (tracking_code.startsWith("14") && tracking_code.length == 4)
          ) {
            this.statusStep = 4;
          } else {
            this.statusStep = 2;
          }

          if (
            (tracking_code.startsWith("45") && tracking_code.length == 3) ||
            (tracking_code.startsWith("10") && tracking_code.length == 4)
          ) {
            this.addInfoColor = "danger";
            this.statusStep = 0;
          } else {
            this.addInfoColor = "";
          }
          if (
            this.order.seller.site &&
            !this.order.seller.site.includes("https://") &&
            !this.order.seller.site.includes("http://")
          ) {
            this.order.seller.site = "https://" + this.order.seller.site;
          }
          if (
            this.order.seller.ad_url &&
            !this.order.seller.ad_url.includes("https://") &&
            !this.order.seller.ad_url.includes("http://")
          ) {
            this.order.seller.ad_url = "https://" + this.order.seller.ad_url;
          }
          this.loaded = false;
          NProgress.done();
        })
        .catch((error) => {
          if (error.response?.status == 404) {
            // track number not found
            if (this.prevRoute.name == "erro_404") {
              this.$router.push({ path: "/", query: { error: 404 } });
            } else {
              this.$router.push("/trackings/404");
            }
          } else if (error.response?.status == 422) {
            // wrong cpf/cnpj
            this.$router.push({ path: "/", query: { error: 422 } });
          } else {
            // Other kind of error
            this.$helpers.toast("An error has ocurred", "danger");
          }
          NProgress.done();
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
};
</script>

<style lang="scss" scoped></style>
